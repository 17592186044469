import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const QUESTIONS = [
  {
    prompt: "Your wife has cheated on you with at least 73 other men, just married another man, and is plotting to kill you.",
    img: "thewife.jpeg",
    options: [
      {text: "Jump out the window", value: 0},
      {text: "Forgive her, because you love her", value: 0},
      {text: "Execute everyone involved in this plot", value: -20},
    ]
  },
  {
    prompt: "It’s time to make some reforms.",
    img: "timeforreform.jpeg",
    options: [
      {text: 'Tax the poor and increase your own salary', value: 0},
      {text: "Give more rights to slaves and ex-slaves", value: -20},
      {text: 'Execute the senate', value: 0},
    ]
  },
  {
    prompt: "Everyone thinks you’re not cool enough to be the emperor.",
    img: "coolemperor.jpeg",
    options: [
      {text: "Execute everyone who doesn’t like you", value: 0},
      {text: "Resign and restore the republic", value: 0},
      {text: "Conquer Britain", value: -20},
    ]
  },
  {
    prompt: "Your niece wants to marry you.",
    img: "wtfniece.jpeg",
    options: [
      {text: "Sounds good", value: -20},
      {text: "That sucks, but I guess I’ll do it for political reasons", value: -20},
      {text: "wtf? but okay", value: -20},
    ]
  },
  {
    prompt: "Your new wife wants you to adopt her son Nero, and she’s definitely not planning to kill you right after, she swears.",
    img: "agnesus.jpeg",
    options: [
      {text: "Nero seems like a fine young man, I’ll adopt him.", value: -19},
      {text: "That kid is insane, no thanks.", value: 0},
      {text: "Execute her for such a ridiculous suggestion", value: -20},
    ]
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const RomanEmperorSimulator = () => {
  const [questionIndex, setQuestionIndex]= useState(0)
  const [prob, setProb] = useState(100)
  const [diff, setDiff] = useState(0)
  // claudius always dies, the speedrun must fail
  // actually if they get it to true 0 then they can live
  const wouldBeKilled = prob > 0 

  const onSelectOption = (option) => {
    setDiff(option.value)
    setProb(prob + option.value)
    setQuestionIndex(questionIndex+1)
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      padding-bottom: 50px;
    `}>
      <h1 css={css`
        margin-bottom: 10px;
        text-align: center;
      `}>Roman Emperor Simulator</h1>
      <img css={css`
        height: 100px;

      `} src="/empire.png" alt="Roman Empire Flag"></img>

      <h3>Your goal is to survive</h3>
      <label for="file">Assassination Probability ({prob}%)</label>
<progress id="file" value={prob} max="100"> {prob}% </progress>
<div css={css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`}>

  {questionIndex < QUESTIONS.length ? (
    <>
      <h2 css={css`
        text-align: center;
        margin-bottom: 5px;
      `}>{QUESTIONS[questionIndex].prompt}</h2>

  <img css={css`
    max-height: 125px;
    margin-top: 0;
  `} src={QUESTIONS[questionIndex].img} alt="something funny"></img>
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  `}>
  {QUESTIONS[questionIndex].options.map((option) => (
    <button css={css`
    margin-top: 20px;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
    width: 350px;
    word-wrap: break-word;
    `}
    onClick={() => onSelectOption(option)}
    >
     { option.text}
      </button>
  ))}
  </div>
  </>
  ) : wouldBeKilled ?  (
    <div css={css`text-align: center;`}>
      <h2>You did *almost* everything right, but unfortunately your wife decided to poison you. </h2>
      <img css={css`
        max-width: 400px;
      `} src="/neroburn.jpeg"/>
      <h3>Long live the new Emperor Nero</h3>
    </div>
  ) : <div css={css`text-align: center;`}>
      <h2>You survived!</h2>
      <img css={css`
       max-width: 400px;
      `} src="/claudius.jpeg"/>
      <h3>Caesar would be proud of you.</h3>
    </div>}

</div>
    </div>
  )
}
  export default RomanEmperorSimulator;